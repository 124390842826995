import React, { useState, useEffect } from 'react';
import './VSeekerTest.css';

const generateRow = () => {
  const row = Array(10).fill('V');
  const randomIndex = Math.floor(Math.random() * 10);
  row[randomIndex] = 'v';
  return row;
};

const VSeekerTest = ({ onComplete }) => {
  const [rows, setRows] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [clickCount, setClickCount] = useState(0);
  const [fadingRow, setFadingRow] = useState(null);

  useEffect(() => {
    const initialRows = Array(10).fill(null).map(generateRow);
    setRows(initialRows);
    setStartTime(Date.now());
  }, []);

  const handleClick = (char, rowIndex) => {
    if (char === 'v') {
      setFadingRow(rowIndex);
      setTimeout(() => {
        const newRows = rows.slice();
        newRows[rowIndex] = generateRow();
        setRows(newRows);
        setFadingRow(null);

        setClickCount(prevCount => {
          if (prevCount + 1 === 15) {
            const elapsedTime = (Date.now() - startTime) / 1000;
            onComplete({ testName: 'V Seeker', score: elapsedTime });
          }
          return prevCount + 1;
        });
      }, 500); // Match this duration with CSS animation duration
    }
  };

  return (
    <div className="v-seeker-test">
      <p>Find 15 lowercase 'v' buttons.</p>
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} className={`v-seeker-row ${fadingRow === rowIndex ? 'fading' : ''}`}>
          {row.map((char, charIndex) => (
            <button
              key={charIndex}
              className="v-seeker-button"
              onClick={() => handleClick(char, rowIndex)}
            >
              {char}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

export default VSeekerTest;
