// src/components/ContactForm.js
import React, { useState } from 'react';
import { db } from '../firebase';
import { collection, addDoc } from 'firebase/firestore';

const ContactForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [honeypot, setHoneypot] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handleMessageChange = (e) => setMessage(e.target.value);
  const handleHoneypotChange = (e) => setHoneypot(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (honeypot) {
      // If honeypot is filled, ignore the submission
      return;
    }

    try {
      await addDoc(collection(db, 'contacts'), { email, message });
      setSuccess(true);
      setEmail('');
      setMessage('');
      setHoneypot('');
    } catch (err) {
      setError('Error submitting contact form. Please try again.');
    }
  };

  return (
    <div>
      <h3>Contact Us</h3>
      {success ? (
        <p>Thank you for your message!</p>
      ) : (
        <form onSubmit={handleSubmit} class="ContactForm">
          <label>
            Email:
            <input type="email" value={email} onChange={handleEmailChange} required />
          </label>
          <label>
            Short Message:
            <textarea value={message} onChange={handleMessageChange} maxLength="256" required />
          </label>
          <label style={{ display: 'none' }}>
            Leave this field blank:
            <input type="text" value={honeypot} onChange={handleHoneypotChange} />
          </label>
          <button type="submit">Submit</button>
          {error && <p>{error}</p>}
        </form>
      )}
    </div>
  );
};

export default ContactForm;
