// src/components/tests/BaseTest.js
import React, { useState, useEffect } from 'react';

const BaseTest = ({ testName, onComplete }) => {
  const [startTime, setStartTime] = useState(null);
  const [score, setScore] = useState(null);

  useEffect(() => {
    // Set the start time when the component mounts
    setStartTime(Date.now());
  }, []);

  const handleClick = () => {
    const endTime = Date.now();
    const elapsedSeconds = (endTime - startTime) / 1000;
    setScore(elapsedSeconds.toFixed(2));
    onComplete({ testName, score: elapsedSeconds });
  };

  return (
    <div>
      <h2>{testName}</h2>
      {score === null ? (
        <button onClick={handleClick}>Click me</button>
      ) : (
        <p>Test Score: {score} seconds</p>
      )}
    </div>
  );
};

export default BaseTest;
