// src/App.js
import React, { useState } from 'react';
import './App.css';
import Home from './components/Home';
import UserForm from './components/UserForm';
import TestSelector from './components/TestSelector';
import Results from './components/Results';
import About from './components/About';
import { db } from './firebase';
import { collection, addDoc } from 'firebase/firestore';

function App() {
  const [formData, setFormData] = useState(null);
  const [testResults, setTestResults] = useState([]);
  const [showResults, setShowResults] = useState(false);
  const [showAbout, setShowAbout] = useState(false);
  const [participate, setParticipate] = useState(false);
  const [testName, setTestName] = useState('');

  const handleFormSubmit = (data) => {
    setFormData(data);
    setParticipate(data.futureStudies);

    if (data.futureStudies && data.email) {
      addDoc(collection(db, 'userEmails'), { email: data.email });
    }
  };

  const handleTestComplete = async (result) => {
    setTestName(result.testName);
    const sessionId = Date.now();
    const resultData = {
      sessionId,
      date: new Date(),
      age: formData.age,
      gender: formData.gender,
      historyStroke: formData.historyStroke,
      historyDiabetes: formData.historyDiabetes,
      historyVision: formData.historyVision,
      historyHearing: formData.historyHearing,
      futureStudies: formData.futureStudies,
      testName: result.testName,
      testScore: result.score // Save score as testScore
    };

    try {
      await addDoc(collection(db, 'testResults'), resultData);
      console.log('Test result saved successfully.');
    } catch (error) {
      console.error('Error saving test result: ', error);
    }

    setTestResults([...testResults, result]);
    setShowResults(true);
  };

  const handleParticipateChange = (e) => {
    setParticipate(true);
  };

  const handleRetake = () => {
    setFormData(null);
    setShowResults(false);
    setParticipate(false);
  };

  const handleNavClick = (page) => {
    setShowAbout(page === 'about');
    setShowResults(page === 'results');
    setFormData(null);
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Visual Acuity and Response Time Test</h1>
      </header>
      <nav>
        <ul>
          <li><button onClick={() => handleNavClick('home')}>Home</button></li>
          <li><button onClick={() => handleNavClick('about')}>About</button></li>
        </ul>
      </nav>
      <main>
        {!formData && !showResults && !showAbout && (
          <>
            <Home />
            <UserForm onSubmit={handleFormSubmit} />
          </>
        )}
        {showAbout && <About />}
        {formData && !showResults && !showAbout && (
          <TestSelector onComplete={handleTestComplete} />
        )}
        {showResults && (
          <Results
            score={testResults[testResults.length - 1]?.score}
            testName={testName}
            age={formData.age}
            participate={participate}
            onParticipateChange={handleParticipateChange}
            onRetake={handleRetake}
          />
        )}
      </main>
    </div>
  );
}

export default App;
