// src/components/Results.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore';

const Results = ({ score, testName, age, participate, onParticipateChange, onRetake }) => {
  const [email, setEmail] = useState('');
  const [cohortRange, setCohortRange] = useState('');
  const [userCount, setUserCount] = useState(0);
  const [percentile, setPercentile] = useState('');
  const [advice, setAdvice] = useState('');

  useEffect(() => {
    const ageNum = Number(age);
    const minAge = ageNum - 5;
    const maxAge = ageNum + 5;
    setCohortRange(`${minAge}-${maxAge}`);

    const fetchResults = async () => {
      try {
        const q = query(
          collection(db, 'testResults'),
          where('age', '>=', minAge),
          where('age', '<=', maxAge),
          where('testName', '==', testName)
        );
        const querySnapshot = await getDocs(q);
        
        console.log('Query Snapshot:', querySnapshot.docs);

        const scores = querySnapshot.docs.map(doc => doc.data().testScore);
        
        console.log('Scores:', scores);

        setUserCount(scores.length);

        if (scores.length > 0) {
          scores.sort((a, b) => a - b); // Sort in ascending order (lower scores are better)
          const userPercentile = (scores.filter(s => s > score).length / scores.length) * 100; // Calculate percentile
          setPercentile(userPercentile.toFixed(2));
          setAdvice(userPercentile > 40 ? '' : 'You might consider a professional opinion on your neurological health.');
        } else {
          setPercentile('0');
          setAdvice('No comparison data available for this age range.');
        }
      } catch (error) {
        console.error('Error fetching results: ', error);
      }
    };

    fetchResults();
  }, [age, score, testName]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleEmailSubmit = async () => {
    try {
      await addDoc(collection(db, 'userEmails'), { email });
      onParticipateChange({ target: { value: true } });
    } catch (error) {
      console.error('Error saving email: ', error);
    }
  };

  return (
    <div>
      <h2>Your Result</h2>
      <p>You completed the <strong>{testName}</strong> test.</p>
      <p>Your result is: <strong>{score.toFixed(3)} seconds</strong></p>
      <p>Your age: <strong>{age}</strong>, which puts you in a cohort of <strong>{cohortRange} year olds</strong>.</p>
      <p>So far, <strong>{userCount}</strong> users in that age range have taken this test.</p>
      <p>Your score was faster than <strong>{percentile}%</strong> of test takers in your age range.</p>
      {advice && <em>{advice}</em>}
      <br /><br /><br />
      <p>Remember, this is not a diagnostic tool. This test is meant for fun and interest, not to diagnose any medical condition. If your score is low for your cohort, you might consider reaction time and visual acuity exercises such as video games. If you believe your performance was related to a medical condition, you should seek professional advice.</p>
      <hr />
      {!participate && (
        <>
          <p>If you would like to participate in future research, please add your email.</p>
          <label>
            Email:
            <input type="email" value={email} onChange={handleEmailChange} />
          </label>
          <button onClick={handleEmailSubmit}>Submit Email</button>
        </>
      )}

      {participate && <p>You chose to participate in future research. Thank you.</p>}
      <br /><br />
      <button onClick={onRetake}>Retake Test</button>
    </div>
  );
};

export default Results;
