import React, { useState } from 'react';
import BaseTest from './tests/BaseTest';
import VSeekerTest from './tests/VSeekerTest';

const TestSelector = ({ onComplete }) => {
  const [selectedTest, setSelectedTest] = useState('');

  const handleTestChange = (e) => {
    setSelectedTest(e.target.value);
  };

  const renderTest = () => {
    switch (selectedTest) {
      case 'base':
        return <BaseTest testName="Base Test" onComplete={onComplete} />;
      case 'vSeeker':
        return <VSeekerTest onComplete={onComplete} />;
      default:
        return <p>Please select a test to begin.</p>;
    }
  };

  return (
    <div>
      {!selectedTest && (
        <label>
          Select Test:
          <select value={selectedTest} onChange={handleTestChange}>
            <option value="">--Select--</option>
            <option value="base">Base Test</option>
            <option value="vSeeker">V Seeker Test</option>
          </select>
        </label>
      )}
      {renderTest()}
    </div>
  );
};

export default TestSelector;
