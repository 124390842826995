// src/components/Home.js
import React from 'react';

const Home = () => {
  return (
    <div>
      <h2>Welcome to the Visual Acuity and Response Time Test</h2>
      <p>
        This application helps you test your visual acuity and response time.
        Please fill out the form below to start the test.
      </p>
    </div>
  );
}

export default Home;
