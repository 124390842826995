// src/components/About.js
import React from 'react';
import ContactForm from './ContactForm';

const About = () => {
  return (
    <div class="leftbody">
      <h2>About Visual Acuity and Response Time</h2>
      <p>This site was designed to help test visual acuity and response time across populations. The tests are simple and fun to be taken by anyone at any time. They are not diagnostic tools.</p>
      <p>Our goal is to provide an engaging way for you to assess and improve your visual and reaction capabilities. The results can give you insights into your performance and help you consider exercises to enhance these skills.</p>
      <p>A secondary goal is to collect anonymous data which can be used for research in the future.</p>
      <p>If you have any concerns about your performance or believe you might have a medical condition, please seek professional advice.</p>
      <hr />
      <ContactForm />
      <hr />
      <p>This site was made by Robert&nbsp;Allen of Zinglab&nbsp;LLC. All rights restricted.</p>
    </div>
  );
};

export default About;
