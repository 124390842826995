import React, { useState } from 'react';

const UserForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    age: '',
    gender: '',
    historyStroke: false,
    historyDiabetes: false,
    historyVision: false,
    historyHearing: false,
    futureStudies: false,
    email: ''
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Ensure age is a number
    const formattedData = {
      ...formData,
      age: Number(formData.age)
    };
    onSubmit(formattedData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Age:
        <input type="number" name="age" value={formData.age} onChange={handleChange} required />
      </label>
      <label>
        Gender:
        <select name="gender" value={formData.gender} onChange={handleChange} required>
          <option value="">Select</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="other">Other</option>
        </select>
      </label>
      <label>
        <input type="checkbox" name="historyStroke" checked={formData.historyStroke} onChange={handleChange} />
        History of Stroke
      </label>
      <label>
        <input type="checkbox" name="historyDiabetes" checked={formData.historyDiabetes} onChange={handleChange} />
        History of Diabetes
      </label>
      <label>
        <input type="checkbox" name="historyVision" checked={formData.historyVision} onChange={handleChange} />
        History of Visual Problems
      </label>
      <label>
        <input type="checkbox" name="historyHearing" checked={formData.historyHearing} onChange={handleChange} />
        History of Hearing Problems
      </label>
      <label>
        <input type="checkbox" name="futureStudies" checked={formData.futureStudies} onChange={handleChange} />
        Participate in Future Studies
      </label>
      {formData.futureStudies && (
        <label>
          Email:
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </label>
      )}
      <button type="submit">Start Test</button>
    </form>
  );
};

export default UserForm;
