import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDntx3K05gVEb8xcI0bAglx9u_JR71jBrM",
  authDomain: "vart-ada8d.firebaseapp.com",
  projectId: "vart-ada8d",
  storageBucket: "vart-ada8d.appspot.com",
  messagingSenderId: "502108521443",
  appId: "1:502108521443:web:c4ac794a74ac7bb5c0d6d6",
  measurementId: "G-YF364YX8BR"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
